import { render, staticRenderFns } from "./FileInputSize.vue?vue&type=template&id=24fc360c"
import script from "./FileInputSize.vue?vue&type=script&lang=js"
export * from "./FileInputSize.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports